<template>
  <div class="home">
    <eden-page-header :title="'Service Partners'">
      <template slot="actions">
        <div class="page--head-actions is-flex is-flex-wrap align-center">
          <el-button type="primary" @click="addServicePartner">
            Add Service Partner
          </el-button>
        </div>
      </template>
    </eden-page-header>
    <div>
      <eden-table-actions :title="title" @search="search">
        <template slot="actions">
          <service-partners-filter
            :class="'mx-10'"
            :disabled="loading"
            :clear="filterParams.clear"
            @filter="filter"
          />
        </template>
      </eden-table-actions>
      <template v-if="loading">Loading...</template>
      <template v-else>
        <eden-filter-items
          v-if="filterParams.status"
          :params="filterParams.paramsLabel"
          @clear-filter="clearFilter"
          @clear-filters="clearFilters"
        />
        <el-table :data="pageData">
          <el-table-column width="30">
            <template v-slot="scope">
              <span
                :class="[
                  'status-indicator',
                  { inactive: !scope.row.active_status },
                ]"
              ></span>
            </template>
          </el-table-column>
          <el-table-column width="300">
            <template slot="header">
              <div class="table--header">
                <span>Name</span>
                <i class="el-icon-bottom"></i>
              </div>
            </template>
            <template v-slot="scope">
              <router-link
                :to="{
                  name: 'serviceoperations.partners.partner',
                  params: { id: scope.row.id },
                }"
              >
                <span class="font-sm">
                  {{ formatName(scope.row.name) }}</span
                ></router-link
              >
            </template>
          </el-table-column>
          <el-table-column width="160">
            <template slot="header">
              <div class="table--header">
                <span>Service</span>
                <i class="el-icon-bottom"></i>
              </div>
            </template>
            <template v-slot="scope">
              <span class="font-sm table--status">
                <el-tag :type="setServiceType(scope.row.service_offering)">{{
                  scope.row.service_offering
                }}</el-tag>
              </span>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <div class="table--header">
                <span>Address</span>
                <i class="el-icon-bottom"></i>
              </div>
            </template>
            <template v-slot="scope">
              <span class="font-sm table--status">
                {{ scope.row.address }}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="200">
            <template slot="header">
              <div class="table--header">
                <span>Phone number</span>
                <i class="el-icon-bottom"></i>
              </div>
            </template>
            <template v-slot="scope">
              <span class="font-sm table--status">
                {{ formatPhoneNumber(scope.row.phone_number) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="200">
            <template slot="header">
              <div class="table--header">
                <span>Status</span>
                <i class="el-icon-bottom"></i>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm table--status">
               <el-tag :type="setType(scope.row.active_status  ? 'Active' :'Inactive')">
                {{ formatText(scope.row.active_status ? "Active" :"Inactive") }}
              </el-tag>
              </span>
            </template>
          </el-table-column>
          <el-table-column v-if="allowAccessFor(['admin', 'operations'])" width="60">
            <template slot-scope="scope">
              <el-dropdown @command="command" class="more">
                <span class="el-dropdown-link">
                  <i class="eden-icon-more" />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                     icon="eden-icon-edit-write"
                    :command="{
                      action: 'change-status',
                      index: scope.$index,
                      partner: scope.row,
                    }"
                    >Change status</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <eden-pagination
          v-if="showPagination"
          :from="from"
          :to="to"
          :total="total"
          :current-page.sync="page"
        />
      </template>

    </div>
    <service-partner-status-change
      :show.sync="showServicePartnerStatusChange"
      :id="partner.id"
      :status="partner.active_status"
      @success="updateStatus"
    />
  </div>
</template>

<script>
import * as actions from "@/store/action-types";
import servicepartners from "@/requests/serviceoperations/servicepartners";
import ServicePartnerStatusChange from "@/components/ServiceOperations/ServicePartners/ServicePartnerStatusChange";
import ServicePartnersFilter from "@/components/ServiceOperations/ServicePartners/ServicePartnersFilter";

export default {
  name: "ServicePartners",
  components:{
    ServicePartnerStatusChange,
    ServicePartnersFilter
  },
  data() {
    return {
      loading: false,
      page: 1,
      pageData: [],
      showPagination: true,
      partner:{},
      showServicePartnerStatusChange:false,
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
    };
  },
  computed: {
    servicePartners() {
      return this.$store.getters.servicepartners;
    },
    from() {
      return this.servicePartners.pages[this.page].from;
    },
    to() {
      return this.servicePartners.pages[this.page].to;
    },
    total() {
      return this.servicePartners.total;
    },
    title() {
      const total = this.total;
      const result = this.pageData.length;
      return `${this.showPagination ? total || 0 : result} Service Partners`;
    },
  },
  watch: {
    page() {
      this.getServicePartners();
    },
  },
  created() {
    this.getServicePartners();
  },
  methods: {
    getServicePartners() {
      this.loading = true;
      this.$store
        .dispatch(actions.GET_SERVICE_PARTNERS, this.page)
        .then(() => {
          this.setPageData();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    command(command) {
      const { index, partner } = command;
      this.partner = { index, ...partner };

      if (command.action === "change-status") {
        this.showServicePartnerStatusChange = true;
      }
    },
    updateStatus(status){
      let data = this.parseData(this.pageData)
      data[this.partner.index].active_status = status ? true :false
      this.pageData = data
    },
    search(query) {
      if (query === "") {
        this.setPageData();
        return;
      }

      this.loading = true;
      servicepartners
        .search(query)
        .then((response) => {
          this.pageData = response.data.partners;
          this.showPagination = false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setPageData() {
      this.pageData = this.servicePartners.pages[this.page].data;
      this.showPagination = !!this.pageData.length;
      this.filterParams = {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      };
    },
    addServicePartner() {
      this.$router.push({ name: "serviceoperations.partners.partner-add" });
    },
    filter({ params, paramsLabel }) {
      this.loading = true;
      servicepartners
        .filter(params)
        .then((response) => {
          this.filterParams = {
            status: true,
            params: params,
            paramsLabel: paramsLabel,
          };
          this.pageData = response.data.partners;
          this.showPagination = false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    clearFilter() {
      this.setPageData();
    },
    clearFilters() {
      this.setPageData();
    },
  },
};
</script>

<style scoped lang="scss">
.status-indicator {
  display: block;
  height: 7px;
  width: 7px;
  border-radius: 100px;
  background: var(--eden-green-primary);

  &.inactive {
    background: #f40529 !important;
  }
}
</style>
