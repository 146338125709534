<template>
  <el-dialog
    title="Change Service Partner Status"
    :visible.sync="shouldShow"
    @close="closeEvent"
    width="30%"
  >
    <el-form :model="form" label-position="top" ref="form">
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="Select status"
            prop="status"
            :rules="validateField()"
          >
            <el-radio-group v-model="form.active_status">
              <el-radio :label="1">Active</el-radio>
              <el-radio :label="0">Inactive</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button
        type="primary"
        :disabled="form.active_status === ''"
        :loading="updating"
        @click="changeStatus"
        >Save changes</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import servicepartners from "@/requests/serviceoperations/servicepartners";

export default {
  name: "ServicePartnerStatusChange",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      default: null,
    },
    status: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        active_status: 0,
      },
      updating: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.form.active_status = this.status ? 1 : 0;
      }
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.updating = false;
    },
    changeStatus() {
      this.updating = true;
      servicepartners
        .updateSpStatus(this.id, this.form)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            // this.$store.dispatch(actions.GET_SERVICE_PARTNERS_LIST);
            this.$emit("success", this.form.active_status);
            this.closeEvent();
          }
        })
        .catch((error) => {
          this.updating = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  line-height: 2;
}
</style>
